import mixpanel from 'mixpanel-browser';

const analyticConfig: any = {
  id: process.env.REACT_APP_MIXPANEL_CONFIG_ID,
};

mixpanel.init(analyticConfig.id, {
  debug: true,
});

export default mixpanel;
