import React from 'react';
import { IconButton, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { ThreatViewTitle, ThreatViewWrapper } from './Style/Home.style';

const ThreatViewToggle = ({ isTableView, handleViewToggle }: any) => {
  return (
    <>
      <ThreatViewWrapper>
        <ThreatViewTitle onClick={() => handleViewToggle('card')}>
          <IconButton>
            <CreditCardIcon color={!isTableView ? 'primary' : 'inherit'} />
          </IconButton>
          <Typography
            variant="body1"
            color={!isTableView ? 'primary' : 'textSecondary'}>
            Card View
          </Typography>
        </ThreatViewTitle>
        <ThreatViewTitle onClick={() => handleViewToggle('table')}>
          <IconButton>
            <TableChartIcon color={isTableView ? 'primary' : 'inherit'} />
          </IconButton>
          <Typography
            variant="body1"
            color={isTableView ? 'primary' : 'textSecondary'}>
            Table View
          </Typography>
        </ThreatViewTitle>
      </ThreatViewWrapper>
    </>
  );
};

export default ThreatViewToggle;
