import { Box, TableCell, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '../../../components';

export const FilterWrapper = styled(Box)(() => ({
  display: 'flex',
  marginTop: '16px',
  marginBottom: '24px',
  justifyContent: 'space-between',
}));

export const CompanyInputStyle = {
  '& .MuiOutlinedInput-root': { borderRadius: 1 },
  mr: 2,
  width: '270px',
};

export const CustomTableCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
}));

export const CompaniesLoader = styled(Box)(() => ({
  width: '-webkit-fill-available',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '50vh',
  position: 'absolute',
}));

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none !important',
  color: 'black',
  '&:hover': {
    color: '#00b2ff',
  },
}));

export const ActionWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: '6px',
}));

export const AddModelWrapper = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '580px',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .1)',
  borderRadius: '.475rem',
};

export const AddCompanyWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 24px 0 24px',
}));

export const LogoWrapper = styled(Box)(() => ({
  padding: '0 24px',
  gap: '6px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const ButtonWrapper = styled(Box)(() => ({
  justifyContent: 'center',
  gap: '14px',
  display: 'flex',
  marginBottom: '24px',
}));

export const EditIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '18px',
  left: '160px',
}));

export const CloseIconWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '160px',
  left: '160px',
}));

export const SearchButton = styled(Button)(() => ({
  background: '#0fd6c2',
  margin: '0 12px',
  padding: '6px 10px',
  height: 'fit-content',
}));
