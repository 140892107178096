import React, { useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import { Button, DatePickers, Input } from '../../components';
import { FilterWrapper } from '../CompaniesList/Style/CompaniesList.style';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import ActiveDefenseTable from './ActiveDefenseTable';
import AddActiveLoadDomainModal from './AddActiveLoadDomainModal';

const ActiveDefense = () => {
  const [openActiveLoadDomain, setOpenActiveLoadDomain] =
    useState<boolean>(false);

  const handleOpenActiveLoadDomain = () => {
    setOpenActiveLoadDomain(true);
  };

  const handleCloseActiveLoadDomain = () => {
    setOpenActiveLoadDomain(false);
  };

  return (
    <>
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
          Network Disruption
        </Typography>
        <FilterWrapper>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Input size="small" placeholder="search for name" />
            <Box sx={{ width: '240px' }}>
              <DatePickers
                size="small"
                // onChange={onChangeDate}
              />
            </Box>
          </Box>
          <Box>
            <Button variant="outlined" sx={{ mr: '12px' }}>
              Export
            </Button>
            <Button
              variant="contained"
              sx={{ background: '#0fd6c2' }}
              onClick={handleOpenActiveLoadDomain}>
              + Load Domain
            </Button>
          </Box>
        </FilterWrapper>
        <ActiveDefenseTable />
        <PaginationStyle>
          <Pagination
            count={5}
            page={10}
            // onChange={handleChangeDomainPage}
            color="primary"
          />
        </PaginationStyle>
      </Box>
      <AddActiveLoadDomainModal
        handleClose={handleCloseActiveLoadDomain}
        open={openActiveLoadDomain}
      />
    </>
  );
};

export default ActiveDefense;
