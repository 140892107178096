import react, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { CloseIconStyle } from './Style/Tabs.style';
import { ButtonWrapper } from '../../CompaniesList/Style/CompaniesList.style';
import { Button, GradientButton, Input } from '../../../components';
import { addDomainKeywordsAction } from '../../../store/company/action';

const AddKeywordDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3, 5),
  },
  '& .MuiDialog-paper': {
    width: '550px',
  },
}));

const AddDomainKeywordModal = ({ handleClose, open, selectedDomain }: any) => {
  const [addDomainKeyword, setAddDomainkeyword] = useState<string>('');

  const dispatch = useDispatch();
  const { id } = useParams();

  const hadleChangeKeyword = (e: any) => {
    setAddDomainkeyword(e.target.value);
  };

  const handleAddDomainKeywords = () => {
    dispatch(
      addDomainKeywordsAction({
        domain: selectedDomain.Name,
        companyId: id,
        keyword: addDomainKeyword,
        action: 'i',
      }),
    );
    handleClose();
  };

  return (
    <>
      <AddKeywordDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Add Protected keyword
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Keyword *
            </Typography>
            <Input
              size="small"
              placeholder="keyword-name"
              value={addDomainKeyword}
              onChange={hadleChangeKeyword}
              sx={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton variant="contained" onClick={handleAddDomainKeywords}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </AddKeywordDialog>
    </>
  );
};

export default AddDomainKeywordModal;
