import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { StatusMenuProps } from '../../helpers/types';

const StatusMenu = ({
  openStatusMenu,
  closeStatusMenu,
  items,
  handleOnClick,
}: StatusMenuProps) => {
  return (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={openStatusMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(openStatusMenu)}
      onClose={closeStatusMenu}>
      {items?.map((item: string) => (
        <MenuItem key={item} onClick={() => handleOnClick(item)}>
          <Typography textAlign="center">{item}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default StatusMenu;
