import React from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Avtar from '../../assets/Images/Avtar.png';
import { ScreenShotModalType } from '../../helpers/types';

const DialogWrapper = styled(Box)({
  height: '500px',
  width: '600px',
});

const ImageWrapper = {
  height: '380px',
  minWidth: '100%',
  '& .MuiAvatar-img': { objectFit: 'cover' },
};

const ScreenShotModal = ({
  imageData,
  handleCloseScreenShot,
  openScreenShotModal,
}: ScreenShotModalType) => {
  return (
    <Dialog open={openScreenShotModal} onClose={handleCloseScreenShot}>
      <DialogWrapper>
        <DialogTitle>Image</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseScreenShot}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Avatar
            alt="Placeholder"
            variant="square"
            src={
              imageData === undefined
                ? Avtar
                : `data:image/jpeg;base64,${imageData}`
            }
            sx={ImageWrapper}
          />
        </DialogContent>
      </DialogWrapper>
    </Dialog>
  );
};

export default ScreenShotModal;
