import {
  AddCompanyType,
  UpdateCompanyDetailsType,
  UpdateCompanyIndustryType,
  UpdateCompanyServiceType,
  addChildCompanyActionType,
  addDomainKeywordsType,
  addInformationGenericType,
  addProtectedDomainActionType,
  changeStatusDomainType,
  deleteCompanyType,
  domainUnprotectType,
  getDomainKeywordsType,
  getProtectedDomainsActionType,
} from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getCompanyApi = async (payload: string) => {
  const config = {
    method: 'GET',
    url: `company/list`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const getCompanyListApi = async (payload: any) => {
  const { d, u, s, h, ipp, pn, r, m, c } = payload;
  const queryParameters = new URLSearchParams({
    d,
    u,
    s,
    h,
    ipp,
    pn,
    ...(r && { r }),
    ...(m && { m }),
    ...(c && { c }),
  }).toString();

  const config = {
    method: 'GET',
    url: `company/all?${queryParameters}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const deleteCompanyApi = async (data: deleteCompanyType) => {
  const config = {
    method: 'POST',
    url: `company/delete`,
    data: { id: data },
  };
  const res = await apiClient(config);
  return res;
};

export const addCompanyApi = async (data: AddCompanyType) => {
  const config = {
    method: 'POST',
    url: `company/add`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addChildCompanyApi = async (data: addChildCompanyActionType) => {
  const config = {
    method: 'POST',
    url: `company/setparent`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const unsetParentCompanyApi = async (data: { companyId: string }) => {
  const config = {
    method: 'POST',
    url: `company/unsetparent`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getCompanyDetailApi = async (payload: { id: string }) => {
  const config = {
    method: 'GET',
    url: `company/get?id=${payload.id}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const getProtectedDomainsApi = async (
  payload: getProtectedDomainsActionType,
) => {
  const config = {
    method: 'GET',
    url: `threat/company?c=${payload.c}&ipp=${payload.ipp}&pn=${payload.pn}&h=${payload.h}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const addProtectedDomainsApi = async (
  data: addProtectedDomainActionType,
) => {
  const config = {
    method: 'POST',
    url: `domain/add`,
    data: { name: data },
  };
  const res = await apiClient(config);
  return res;
};

export const domainUnprotectApi = async (data: domainUnprotectType) => {
  const config = {
    method: 'POST',
    url: `company/unprotect`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addInformationGenericApi = async (
  data: addInformationGenericType,
) => {
  const config = {
    method: 'POST',
    url: `domain/addInformation`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const changeStatusDomainApi = async (data: changeStatusDomainType) => {
  const config = {
    method: 'POST',
    url: `domainprotection/addStatus`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getDomainKeywordsApi = async (payload: getDomainKeywordsType) => {
  const config = {
    method: 'GET',
    url: `domain/keywords?c=${payload.c}&d=${payload.d}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const addDomainKeywordsApi = async (data: addDomainKeywordsType) => {
  const config = {
    method: 'POST',
    url: `domain/keyword`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addDomainIdProtectApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `company/protect`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getServiceApi = async (payload: string) => {
  const config = {
    method: 'GET',
    url: `v2/assets/services`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const updateCompanyDetailsApi = async (
  data: UpdateCompanyDetailsType,
) => {
  const config = {
    method: 'POST',
    url: `company/update`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const updateCompanyIndustryApi = async (
  data: UpdateCompanyIndustryType,
) => {
  const config = {
    method: 'POST',
    url: `company/setProperty`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const updateCompanyServiceApi = async (
  data: UpdateCompanyServiceType,
) => {
  const config = {
    method: 'POST',
    url: `company/addservice`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const getKeywordsApi = async (payload: { c: string }) => {
  const config = {
    method: 'GET',
    url: `domainprotection/listKeywords?c=${payload.c}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};
