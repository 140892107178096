import React from 'react';
import {
  Box,
  Collapse,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoaderWrapper } from './Style/Tabs.style';
import { Loader } from '../../../components';

const UserDetailsStyle = styled(Box)(() => ({
  paddingBottom: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '50%',
  alignItems: 'center',
  borderBottom: '1px solid gray',
  borderBottomStyle: 'dashed',
}));

const TextLabel = {
  fontWeight: 600,
};

const UserDetailsAccordion = ({ openAccordionId, item }: any) => {
  const { getUserDetails } = useSelector((state: any) => state.user);
  const userDetails = getUserDetails.data;

  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAccordionId === item.Id}>
            {getUserDetails.loading ? (
              <LoaderWrapper>
                <Loader size={25} />
              </LoaderWrapper>
            ) : (
              <Box sx={{ marginY: 2 }}>
                <UserDetailsStyle>
                  <Typography variant="body2" sx={TextLabel}>
                    Details
                  </Typography>
                  <Box>
                    <Typography variant="body2" sx={TextLabel}>
                      {userDetails.Username}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'gray',
                        fontWeight: 600,
                      }}>
                      User Job
                    </Typography>
                  </Box>
                </UserDetailsStyle>
                <Box sx={{ my: 1 }}>
                  <Typography variant="body2" sx={TextLabel}>
                    Account ID :
                  </Typography>
                  <Typography variant="body2">{userDetails.Id}</Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Typography variant="body2" sx={TextLabel}>
                    Email :
                  </Typography>
                  <Typography variant="body2">{userDetails.Email}</Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Typography variant="body2" sx={TextLabel}>
                    Company Name :
                  </Typography>
                  <Typography variant="body2">
                    {userDetails.Authorizations?.map(
                      (item: any) => item.Company.Name,
                    )}
                  </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Typography variant="body2" sx={TextLabel}>
                    Roles :
                  </Typography>
                  <Typography variant="body2">
                    {userDetails.Authorizations?.map(
                      (item: any) => item.Roles[0]?.Name,
                    )}
                  </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Typography variant="body2" sx={TextLabel}>
                    Address :
                  </Typography>
                  <Typography variant="body2">
                    {userDetails.Address || 'no address'}
                  </Typography>
                </Box>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserDetailsAccordion;
