import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '../../../components';
import { formatedDate } from '../../../utils';
import UpdateDomainStatusModal from './UpdateDomainStatusModal';
import AddDomainKeywordModal from './AddDomainKeywordModal';
import { getDomainKeywordsAction } from '../../../store/company/action';
import { DomainDetailsStyle, KeywordsLabel } from './Style/Tabs.style';

const TextLabel = {
  fontWeight: 600,
};

const ViewDomainDetails = ({ selectedDomain, onBack }: any) => {
  const [openDomainStatusModal, setOpenDomainStatusModal] =
    useState<boolean>(false);
  const [openAddKeywordModal, setOpenAddKeywordModal] =
    useState<boolean>(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const { getDomainKeywords } = useSelector((state: any) => state.company);

  useEffect(() => {
    dispatch(
      getDomainKeywordsAction({
        c: id,
        d: selectedDomain.Name,
      }),
    );
  }, [selectedDomain]);

  const handleOpenUpdateDomain = () => {
    setOpenDomainStatusModal(true);
  };

  const handleOpenDomainKeyword = () => {
    setOpenAddKeywordModal(true);
  };

  return (
    <>
      <Button
        onClick={onBack}
        variant="outlined"
        size="small"
        startIcon={<ArrowBackIcon />}
        sx={{ marginBottom: '16px' }}>
        Back
      </Button>
      <DomainDetailsStyle>
        <Typography variant="subtitle1" sx={TextLabel}>
          Details
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpenUpdateDomain}>
          Edit Domain
        </Button>
      </DomainDetailsStyle>
      <Box sx={{ mb: 1, mt: 2 }}>
        <Typography variant="body2" sx={TextLabel}>
          Domain ID :
        </Typography>
        <Typography variant="body2">{selectedDomain.Id}</Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2" sx={TextLabel}>
          Domain Name :
        </Typography>
        <Typography variant="body2">{selectedDomain.Name}</Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2" sx={TextLabel}>
          IP Address :
        </Typography>
        <Typography variant="body2">
          {selectedDomain.IPAddress || '-'}
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2" sx={TextLabel}>
          Last Seen :
        </Typography>
        <Typography variant="body2">
          {formatedDate(selectedDomain.Created)}
        </Typography>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography variant="body2" sx={TextLabel}>
          Status :
        </Typography>
        <Typography variant="body2">{selectedDomain.Status}</Typography>
      </Box>
      <DomainDetailsStyle sx={{ mt: 6 }}>
        <Typography variant="subtitle1" sx={TextLabel}>
          Keywords
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpenDomainKeyword}>
          Add Keyword
        </Button>
      </DomainDetailsStyle>
      <Box sx={{ mb: 1, mt: 2 }}>
        <Typography variant="body2" sx={TextLabel}>
          protected Keywords
        </Typography>
        {getDomainKeywords.data.map((item: any, index: number) => (
          <KeywordsLabel key={index}>{item.Keyword}</KeywordsLabel>
        ))}
      </Box>
      <UpdateDomainStatusModal
        handleClose={() => setOpenDomainStatusModal(false)}
        open={openDomainStatusModal}
        selectedDomain={selectedDomain}
      />
      <AddDomainKeywordModal
        handleClose={() => setOpenAddKeywordModal(false)}
        open={openAddKeywordModal}
        selectedDomain={selectedDomain}
      />
    </>
  );
};

export default ViewDomainDetails;
