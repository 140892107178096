import {
  CSSObject,
  Theme,
  styled,
  Drawer as MuiDrawer,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';

const drawerWidth = 210;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: 1201,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const CustomListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'isActive' && prop !== 'open',
})<any>(({ theme, isActive, open }) => ({
  minHeight: 48,
  justifyContent: open ? 'initial' : 'center',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  backgroundColor: isActive ? 'hsl(210, 100%, 96%)' : 'inherit',
  color: isActive ? 'hsl(210, 100%, 42%)' : 'inherit',
  borderTopRightRadius: '40px',
  borderBottomRightRadius: '40px',
}));

export const CustomListItemIcon = styled(ListItemIcon, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive }) => ({
  minWidth: 0,
  marginRight: '12px',
  justifyContent: 'center',
  color: isActive ? 'hsl(210, 100%, 42%)' : 'inherit',
}));
