import React, { useEffect } from 'react';
import { LinearProgress, Box } from '@mui/material';

const ProgressBar = () => {
  return (
    <Box sx={{ width: '100%', mt: '-4px' }}>
      <LinearProgress sx={{ mx: '8px' }} />
    </Box>
  );
};

export default ProgressBar;
