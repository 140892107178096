import {
  AddCompanyType,
  GetCompanyActionType,
  GetServiceActionType,
  addChildCompanyActionType,
  addProtectedDomainActionType,
  getProtectedDomainsActionType,
  UpdateCompanyDetailsType,
  UpdateCompanyIndustryType,
  UpdateCompanyServiceType,
  deleteCompanyType,
  domainUnprotectType,
  changeStatusDomainType,
  getDomainKeywordsType,
  addDomainKeywordsType,
  addInformationGenericType,
} from '../../helpers/types';
import {
  ADD_CHILD_COMPANY_BEGIN,
  ADD_COMPANY_BEGIN,
  ADD_PROTECTED_DOMAINS_BEGIN,
  DELETE_COMPANY_BEGIN,
  GET_COMPANYDETAIL_BEGIN,
  GET_COMPANYLIST_BEGIN,
  GET_COMPANY_BEGIN,
  GET_PROTECTED_DOMAINS_BEGIN,
  GET_KEYWORDS_BEGIN,
  GET_SERVICE_BEGIN,
  UPDATE_COMPANY_DETAILS_BEGIN,
  UPDATE_COMPANY_INDUSTRY_BEGIN,
  UPDATE_COMPANY_SERVICE_BEGIN,
  DOMAIN_UNPROTECT_BEGIN,
  CHANGE_STATUS_DOMAIN_BEGIN,
  GET_DOMAIN_KEYWORDS_BEGIN,
  ADD_DOMAIN_KEYWORDS_BEGIN,
  UNSET_PARENT_COMPANY_BEGIN,
  ADDINFORMATION_GENERIC_BEGIN,
  SET_DATE_PICKER_PERSIST_VALUES,
  SET_COMPANY_PERSIST_VALUES,
  SET_EXCLUDE_COMPANY,
  SET_SERVICES_DATA,
  SET_SOURCES_DATA,
  SET_DATE_PICKER_STARTDATE,
  SET_DATE_PICKER_ENDDATE,
} from '../constant';

export const getCompanyAction = (payload?: GetCompanyActionType) => ({
  type: GET_COMPANY_BEGIN,
  payload,
});

export const setCompanyPersistValues = (values: string[]) => ({
  type: SET_COMPANY_PERSIST_VALUES,
  payload: values,
});

export const setDatePickerPersistValues = (values: {
  startDate: string;
  endDate: string;
}) => ({
  type: SET_DATE_PICKER_PERSIST_VALUES,
  payload: values,
});

export const getCompanyListAction = (payload: any) => ({
  type: GET_COMPANYLIST_BEGIN,
  payload,
});

export const deleteCompanyAction = (payload: deleteCompanyType) => ({
  type: DELETE_COMPANY_BEGIN,
  payload,
});

export const addCompanyAction = (payload: AddCompanyType) => ({
  type: ADD_COMPANY_BEGIN,
  payload,
});

export const getCompanyDetailAction = (payload: { id: string }) => ({
  type: GET_COMPANYDETAIL_BEGIN,
  payload,
});

export const getProtectedDomainsAction = (
  payload: getProtectedDomainsActionType,
) => ({
  type: GET_PROTECTED_DOMAINS_BEGIN,
  payload,
});

export const addProtectedDomainAction = (
  payload: addProtectedDomainActionType,
) => ({
  type: ADD_PROTECTED_DOMAINS_BEGIN,
  payload,
});

export const domainUnprotectAction = (payload: domainUnprotectType) => ({
  type: DOMAIN_UNPROTECT_BEGIN,
  payload,
});

export const addInformationGenericAction = (
  payload: addInformationGenericType,
) => ({
  type: ADDINFORMATION_GENERIC_BEGIN,
  payload,
});

export const changeStatusDomainAction = (payload: changeStatusDomainType) => ({
  type: CHANGE_STATUS_DOMAIN_BEGIN,
  payload,
});

export const getDomainKeywordsAction = (payload: getDomainKeywordsType) => ({
  type: GET_DOMAIN_KEYWORDS_BEGIN,
  payload,
});

export const addDomainKeywordsAction = (payload: addDomainKeywordsType) => ({
  type: ADD_DOMAIN_KEYWORDS_BEGIN,
  payload,
});

export const addChildCompanyAction = (payload: addChildCompanyActionType) => ({
  type: ADD_CHILD_COMPANY_BEGIN,
  payload,
});

export const unsetParentCompanyAction = (
  payload: addChildCompanyActionType,
) => ({
  type: UNSET_PARENT_COMPANY_BEGIN,
  payload,
});

export const getServiceAction = (payload?: GetServiceActionType) => ({
  type: GET_SERVICE_BEGIN,
  payload,
});

export const updateCompanyDetailsAction = (
  payload: UpdateCompanyDetailsType,
) => ({
  type: UPDATE_COMPANY_DETAILS_BEGIN,
  payload,
});

export const updateCompanyIndustryAction = (
  payload: UpdateCompanyIndustryType,
) => ({
  type: UPDATE_COMPANY_INDUSTRY_BEGIN,
  payload,
});

export const updateCompanyServiceAction = (
  payload: UpdateCompanyServiceType,
) => ({
  type: UPDATE_COMPANY_SERVICE_BEGIN,
  payload,
});

export const getKeywordsAction = (payload: { c: string }) => ({
  type: GET_KEYWORDS_BEGIN,
  payload,
});

export const setExcludeCompany = (values: string[]) => ({
  type: SET_EXCLUDE_COMPANY,
  payload: values,
});

export const setServicesData = (values:{ label: string }[]) =>({
  type: SET_SERVICES_DATA,
  payload: values,
});

export const setSourcesData = (values:{ label: string; value: string }[]) =>({
  type: SET_SOURCES_DATA,
  payload: values,
});

export const setPickerStartDate = (values:string) =>({
  type: SET_DATE_PICKER_STARTDATE,
  payload: values,
});

export const setPickerEndDate = (values:string) =>({
  type: SET_DATE_PICKER_ENDDATE,
  payload: values,
})
