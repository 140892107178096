import React from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';
import { SearchableDropDownProps } from '../../helpers/types';

const SearchableDropDown = ({
  items,
  size,
  placeholder,
  label,
  onChange,
  selectedValues,
  multiple,
}: SearchableDropDownProps) => {
  return (
    <Autocomplete
      sx={{ width: 1 }}
      multiple={multiple}
      options={items}
      getOptionLabel={(option: any) => option.label}
      disableCloseOnSelect
      onChange={onChange}
      value={selectedValues}
      isOptionEqualToValue={(option: any, value) =>
        option.label === value.label
      }
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id || option.label}>
          {option.label}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          size={size}
        />
      )}
    />
  );
};

export default SearchableDropDown;
