import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { hideToast } from '../../store/toaster/action';

function ToastMui() {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(
    (state: any) => state.toaster.toster,
  );

  const handleClose = (event: React.SyntheticEvent | Event, reason?: any) => {
    if (reason === 'clickaway') return;
    dispatch(hideToast());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      {message && severity ? (
        <Alert
          onClose={handleClose}
          severity={severity || 'info'}
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      ) : (
        <div style={{ display: 'none' }} />
      )}
    </Snackbar>
  );
}

export default ToastMui;
