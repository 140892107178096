import * as React from 'react';
import { Skeleton } from '@mui/material';

interface ImageSkeletonType {
  height?: number;
  width?: number | string;
}

const ImageSkeleton = ({ height, width = '100%' }: ImageSkeletonType) => {
  return (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={width}
      height={height}
    />
  );
};
export default ImageSkeleton;
