import React from 'react';
import { InputLabel, Box, MenuItem, FormControl, Select } from '@mui/material';
import { SelectOptionProps } from '../../helpers/types';

const SelectOption = ({
  label,
  value,
  defaultValue,
  onChange,
  options,
  width,
  placeholder,
}: SelectOptionProps) => {
  return (
    <Box sx={{ width: `${width}` }}>
      <FormControl size="small" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          label={label}
          onChange={onChange}
          displayEmpty
          defaultValue={defaultValue}>
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options?.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectOption;
