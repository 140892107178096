import { call, put } from 'redux-saga/effects';
import { showToast } from '../toaster/action';
import {
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_LABLE_FAILURE,
  GET_LABLE_SUCCESS,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS,
  GET_NETWORK_FAILURE,
  GET_NETWORK_SUCCESS,
  GET_PLATFORM_FAILURE,
  GET_PLATFORM_SUCCESS,
  GET_REGION_CODE_FAILURE,
  GET_REGION_CODE_SUCCESS,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
  SUBMIT_TO_GSB_FAILURE,
  SUBMIT_TO_GSB_SUCCESS,
} from '../constant';
import {
  getHistoryStatuesApi,
  getSubDomainsApi,
  getNetworkApi,
  fetchPlatformApi,
  fetchLabelApi,
  fetchRegionCodesApi,
  fetchNetworkCheckThreatApi,
  submitToGsbApi,
} from '.';

export function* getSubDomainsSaga(action: any): any {
  try {
    const response = yield call(getSubDomainsApi, action.payload);
    yield put({
      type: GET_SUBDOMAINS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SUBDOMAINS_FAILURE,
      payload: error.message,
    });
  }
}

export function* getNetworkSaga(action: any): any {
  try {
    const response = yield call(getNetworkApi, action.payload);
    yield put({
      type: GET_NETWORK_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NETWORK_FAILURE,
      payload: error.message,
    });
  }
}

export function* getHistoryStatuesSaga(action: any): any {
  try {
    const response = yield call(getHistoryStatuesApi, action.payload);
    yield put({
      type: GET_HISTORY_STATUSES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_HISTORY_STATUSES_FAILURE,
      payload: error.message,
    });
  }
}

export function* fetchNetworkCheckThreatSaga(): any {
  try {
    const response = yield call(fetchNetworkCheckThreatApi);
    yield put({
      type: GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE,
      payload: error.message,
    });
  }
}

export function* fetchRegionCodetSaga(): any {
  try {
    const response = yield call(fetchRegionCodesApi);
    yield put({
      type: GET_REGION_CODE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_REGION_CODE_FAILURE,
      payload: error.message,
    });
  }
}

export function* fetchPlatformSaga(): any {
  try {
    const response = yield call(fetchPlatformApi);
    yield put({
      type: GET_PLATFORM_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_PLATFORM_FAILURE,
      payload: error.message,
    });
  }
}

export function* fetchLableSaga(): any {
  try {
    const response = yield call(fetchLabelApi);
    yield put({
      type: GET_LABLE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_LABLE_FAILURE,
      payload: error.message,
    });
  }
}

export function* submitToGsbSaga(action: any): any {
  try {
    const response = yield call(submitToGsbApi, action.payload);

    yield put(showToast('Submission successful!', 'success'));

    yield put({
      type: SUBMIT_TO_GSB_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error.message || 'Submission failed, please try again.',
        'error',
      ),
    );

    yield put({
      type: SUBMIT_TO_GSB_FAILURE,
      payload: error.message,
    });
  }
}

