import { call, put } from 'redux-saga/effects';
import { showToast } from '../toaster/action';
import {
  COMPLETE_TAKEDOWN_FAILURE,
  COMPLETE_TAKEDOWN_SUCCESS,
  CREATE_TAKEDOWN_FAILURE,
  CREATE_TAKEDOWN_SUCCESS,
  GET_HUBSPORTID_FAILURE,
  GET_HUBSPORTID_SUCCESS,
  GET_STATUS_TAKEDOWN_FAILURE,
  GET_STATUS_TAKEDOWN_SUCCESS,
  GET_TAKEDOWN_THREAT_DETAIL_FAILURE,
  GET_TAKEDOWN_THREAT_DETAIL_SUCCESS,
  GET_TAKEDOWNLIST_FAILURE,
  GET_TAKEDOWNLIST_SUCCESS,
  TAKEDOWN_STATUS_HISTRORY_FAILURE,
  TAKEDOWN_STATUS_HISTRORY_SUCCESS,
  UPDATE_ENRICH_TAKEDOWN_FAILURE,
  UPDATE_ENRICH_TAKEDOWN_SUCCESS,
} from '../constant';
import {
  completeTakedownApi,
  createTakedownApi,
  getHubSportIdApi,
  getTakedownDetailApi,
  getTakeDownListApi,
  getTakedownStatusApi,
  takedownStatusHistoryApi,
  updateEnrichTakedownApi,
} from '.';

export function* getTakeDownListSaga(action: any): any {
  try {
    const response = yield call(getTakeDownListApi, action.payload);
    yield put({
      type: GET_TAKEDOWNLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_TAKEDOWNLIST_FAILURE,
      payload: error.message,
    });
  }
}

export function* createTakedownSaga(action: any): any {
  try {
    const response = yield call(createTakedownApi, action.payload);
    yield put(showToast('Takedown created successfully', 'success'));
    yield put({
      type: CREATE_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: CREATE_TAKEDOWN_FAILURE,
      payload: error.response.data.detail,
    });
    yield put(
      showToast(
        error.response.data.detail[0].msg ||
          error.response.data.detail ||
          'Failed to create takedown',
        'error',
      ),
    );
  }
}

export function* completeTakedownSaga(action: any): any {
  try {
    const response = yield call(completeTakedownApi, action.payload);
    yield put(
      showToast(
        response.data.message || 'Takedown completed successfully',
        'success',
      ),
    );
    yield put({
      type: COMPLETE_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
    if (action.payload.takedownPage) {
      const resGetTakedownList = yield call(getTakeDownListApi, {
        since: action.payload.since,
        until: action.payload.until,
        threat_domain: '',
        target_domain: '',
        company_id: '',
        page: 1,
        size: 100,
      });
      yield put({
        type: GET_TAKEDOWNLIST_SUCCESS,
        payload: resGetTakedownList.data,
      });
    }
  } catch (error: any) {
    yield put(
      showToast(
        error.response.data.detail || 'Failed to complete takedown',
        'error',
      ),
    );
    yield put({
      type: COMPLETE_TAKEDOWN_FAILURE,
      payload: error.response.data.detail,
    });
  }
}

export function* getHubSportIdSaga(action: any): any {
  try {
    const response = yield call(getHubSportIdApi, action.payload);
    yield put({
      type: GET_HUBSPORTID_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_HUBSPORTID_FAILURE,
      payload: error.message,
    });
  }
}

export function* getTakedownDetailSaga(action: any): any {
  try {
    const response = yield call(getTakedownDetailApi, action.payload);
    yield put({
      type: GET_TAKEDOWN_THREAT_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_TAKEDOWN_THREAT_DETAIL_FAILURE,
      payload: error.response.data.error,
    });
  }
}

export function* getTakedownStatusSaga(action: any): any {
  try {
    const response = yield call(getTakedownStatusApi, action.payload);
    yield put({
      type: GET_STATUS_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_STATUS_TAKEDOWN_FAILURE,
      payload: error.response.data.error,
    });
    yield put(
      showToast(
        error.response.data.error || 'Failed to retrieve takedown status',
        'error',
      ),
    );
  }
}

export function* takedownStatusHistorySaga(action: any): any {
  try {
    const response = yield call(takedownStatusHistoryApi, action.payload);
    yield put({
      type: TAKEDOWN_STATUS_HISTRORY_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: TAKEDOWN_STATUS_HISTRORY_FAILURE,
      payload: error.response.data.error,
    });
    yield put(
      showToast(
        error.response.data.error ||
          'Failed to retrieve takedown status history',
        'error',
      ),
    );
  }
}

export function* updateEnrichTakedownSaga(action: any): any {
  try {
    const response = yield call(updateEnrichTakedownApi, action.payload);
    yield put(
      showToast(
        response.data.message || 'Manual takedown updated successfully!',
        'success',
      ),
    );
    yield put({
      type: UPDATE_ENRICH_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_ENRICH_TAKEDOWN_FAILURE,
      payload: error.response.data.detail,
    });
    yield put(
      showToast(
        error.response.data.detail ||
          'Failed to update takedown enrichment. Please try again.',
        'error',
      ),
    );
  }
}
