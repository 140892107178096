import mixpanelll from "../services/mixpanelService";

export const EVENT_NAME = {
  TIME_TO_CONFIRM_MALCIOUSNESS: 'timeToConfirmMalciousness',
  CHANGE_THREAT_STATUS: 'changeThreatStatus'
}

export const PAGE_NAME = {
  SINGLE_THREAT_PAGE: 'singleThreatPage',
  HOME_PAGE: 'homePage'
}

export const trackAnalytic = (event: string, properties: any) => {
  return mixpanelll.track(event, properties);
}