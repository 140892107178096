import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoaderContainer } from '../components/Layout/Style/Layout.style';
import { Loader } from '../components';

const PrivateRoute = ({ element }: any) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader size={30} />
      </LoaderContainer>
    );
  }

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute;
