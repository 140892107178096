import * as React from 'react';
import TextField from '@mui/material/TextField';
import { InputProps } from '../../helpers/types';

const Input = ({
  variant = 'outlined',
  label,
  name,
  type,
  onChange,
  error,
  value,
  helperText,
  size,
  placeholder,
  multiline,
  required,
  rows,
  sx,
  ...props
}: InputProps) => {
  return (
    <TextField
      name={name}
      label={label}
      variant={variant}
      value={value}
      error={error}
      helperText={helperText}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      multiline={multiline}
      rows={rows}
      sx={sx}
      required={required}
      {...props}
    />
  );
};

export default Input;
