// TODO Configurations

export const providerConfig: any = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECTURL,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
};
