import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '../../components';
import {
  EnrichContainer,
  EnrichLabel,
  EnrichWrapper,
  FormBox,
  FormSubtitle,
  FormTitle,
} from './Style/TakedownList.style';
import { updateEnrichTakedownAction } from '../../store/takedown/action';

const EnrichTakedown = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const takedownId = queryParams.get('id') || '';

  const { takedownList } = useSelector((state: any) => state.takedown);
  const [formData, setFormData] = useState({
    takedownId: '',
    userId: '',
    takedownUrl: '',
    abuseEmail: '',
    registrarName: '',
    aRecord: '',
    mxRecord: '',
  });

  useEffect(() => {
    if (takedownList && takedownList.data) {
      const foundItem = takedownList?.data?.items?.find(
        (item: any) => item.id === takedownId,
      );
      if (foundItem) {
        setFormData(prevState => ({
          ...prevState,
          takedownId: foundItem.id || '',
          userId: foundItem.user_id || '',
          takedownUrl: foundItem.url || '',
          aRecord: foundItem.aRecord || '',
          mxRecord: foundItem.mxRecord || '',
        }));
      }
    }
  }, [takedownId, takedownList]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEnrichTakedown = () => {
    dispatch(
      updateEnrichTakedownAction({
        takedown_id: formData.takedownId,
        abuse_email: formData.abuseEmail,
        registrar_name: formData.registrarName,
        A_record: formData.aRecord,
        MX_record: formData.mxRecord,
      }),
    );
    navigate('/takedown');
  };

  return (
    <>
      <EnrichWrapper>
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: 'black'}}>
          Enrich Takedown
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
          sx={{ margin: '16px 0' }}>
          Back
        </Button>
        <EnrichContainer>
          <FormBox>
            <FormTitle variant="h5">Enrich Takedown Manually</FormTitle>
            <FormSubtitle>
              Please fill in the fields to resume the takedown.
            </FormSubtitle>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>Takedown ID</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{formData.takedownId || 'Not Found'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>User ID</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{formData.userId || '-'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>Takedown URL</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{formData.takedownUrl || '-'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>Abuse Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.abuseEmail}
                  name="abuseEmail"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>Registrar Name</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.registrarName}
                  name="registrarName"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>A Record</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.aRecord}
                  name="aRecord"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography sx={EnrichLabel}>MX Record</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  value={formData.mxRecord}
                  name="mxRecord"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '24px' }}
                onClick={handleEnrichTakedown}>
                Update Takedown
              </Button>
            </Box>
          </FormBox>
        </EnrichContainer>
      </EnrichWrapper>
    </>
  );
};

export default EnrichTakedown;
