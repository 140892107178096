import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';
import { DNSRecordProps } from '../../../helpers/types';
import { TableHeaderCell, TakedownTable } from '../Style/SingleThreat.style';
import { DNSRecordsHeader } from '../../../utils';

const DNSRecordsTab = ({ dnsRecord }: DNSRecordProps) => {
  const filteredRecords = dnsRecord?.filter(
    (item: any) =>
      item.record_type === 'A' ||
      item.record_type === 'AAAA' ||
      item.record_type === 'MX',
  );

  return (
    <>
      <TakedownTable>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {DNSRecordsHeader.map((item, index) => (
                  <TableHeaderCell key={index} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords && filteredRecords.length > 0 ? (
                filteredRecords.map((item: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '50px',
                    }}>
                    <TableCell>{item?.record_type || '-'}</TableCell>
                    <TableCell align="left">{item?.address || '-'}</TableCell>
                    <TableCell align="left">
                      {item?.domain_name || '-'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={DNSRecordsHeader.length}
                    align="center"
                    sx={{ padding: '30px' }}>
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TakedownTable>
    </>
  );
};

export default DNSRecordsTab;
