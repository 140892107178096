import React, { useEffect } from 'react';
import { Box, Paper, styled, TableContainer, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SyncIcon from '@mui/icons-material/Sync';
import {
  getNetworkAction,
  getSubDomainsAction,
} from '../../store/singleThreat/action';
import DNSRecordsTab from './ThreadsMoreDetails/DNSRecordsTab';
import SSLCertificateTab from './ThreadsMoreDetails/SSLCertificateTab';
import WhoisTab from './ThreadsMoreDetails/WhoisTab';
import { ThreatTypes } from '../../helpers/types';
import SubDomainsTab from './ThreadsMoreDetails/SubDomainsTab ';
import {
  NoDataWrapper,
  SubTitleWrapper,
  SyncIconStyle,
  TabsLoaderWrapper,
  WhoisLoaderWrapper,
} from './Style/SingleThreat.style';
import { Loader } from '../../components';

const threatDetailLoader = () => {
  return (
    <TabsLoaderWrapper>
      <Loader size={25} />
    </TabsLoaderWrapper>
  );
};

const whoisLoader = () => {
  return (
    <WhoisLoaderWrapper>
      <Loader size={25} />
    </WhoisLoaderWrapper>
  );
};

const noRecordsFound = () => {
  return <NoDataWrapper>No Data Found</NoDataWrapper>;
};

const DomainInformation = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const dispatch = useDispatch();
  const { subDomains, getNetwork } = useSelector(
    (state: any) => state.singleThreat,
  );

  useEffect(() => {
    if (singleThreat?.protected_asset_id) {
      dispatch(getSubDomainsAction(singleThreat?.protected_asset_id));
      dispatch(getNetworkAction(singleThreat?.protected_asset_id));
    }
  }, [singleThreat?.protected_asset_id]);

  const sslData = getNetwork?.data?.items?.find(
    (item: any) => item.type === 'ssl_certificate',
  );
  const whoisData = getNetwork?.data?.items?.find(
    (item: any) => item.type === 'whois',
  );
  const dnsRecord = getNetwork?.data?.items?.find(
    (item: any) => item.type === 'dns_records',
  );

  const handleRefreshDomain = () => {
    if (singleThreat?.protected_asset_id) {
      dispatch(getNetworkAction(singleThreat?.protected_asset_id));
    }
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Domain Information
      </Typography>
      <Box sx={{ ml: 2, mt: 1 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            SSL Certificate
          </Typography>
          <SyncIcon sx={SyncIconStyle} onClick={handleRefreshDomain} />
        </SubTitleWrapper>
        {getNetwork?.loading ? (
          threatDetailLoader()
        ) : sslData?.data?.items?.length === 0 ? (
          <NoDataWrapper>SSL Certificate Not listed</NoDataWrapper>
        ) : (
          <SSLCertificateTab sslCertificate={sslData} />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 1.5 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            Whois
          </Typography>
          <SyncIcon sx={SyncIconStyle} onClick={handleRefreshDomain} />
        </SubTitleWrapper>
        {getNetwork?.loading ? (
          whoisLoader()
        ) : whoisData?.data?.length === 0 ? (
          noRecordsFound()
        ) : (
          <WhoisTab whois={whoisData} />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 2 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            Records
          </Typography>
          <SyncIcon sx={SyncIconStyle} onClick={handleRefreshDomain} />
        </SubTitleWrapper>
        {getNetwork?.loading ? (
          threatDetailLoader()
        ) : (
          <DNSRecordsTab dnsRecord={dnsRecord?.data} />
        )}
      </Box>
      <Box sx={{ ml: 2, mt: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'black' }}>
          Subdomains
        </Typography>
        <TableContainer
          sx={{ mt: 0.5, p: 2, width: 'auto', height: '250px' }}
          component={Paper}>
          {subDomains?.loading ? (
            threatDetailLoader()
          ) : subDomains?.data?.length === 0 ? (
            noRecordsFound()
          ) : (
            <SubDomainsTab subDomains={subDomains?.data} />
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default DomainInformation;
