import {
  CreateCommentsType,
  DeleteCommentsType,
  DeleteNoteType,
  GetThreatNotesType,
  UpdateCommentsType,
  getCommentsType,
} from '../../helpers/types';
import {
  CREATE_COMMENTS_BEGIN,
  CREATE_NOTES_BEGIN,
  DELETE_NOTES_BEGIN,
  GET_COMMENTS_BEGIN,
  GET_NOTES_VALUES_BEGIN,
  UPDATE_COMMENTS_BEGIN,
  GET_NOTES_ID_BEGIN,
  DELETE_COMMENTS_BEGIN,
} from '../constant';

export const getNotesValuesAction = () => ({
  type: GET_NOTES_VALUES_BEGIN,
});

export const getNotesIdAction = (payload: GetThreatNotesType) => ({
  type: GET_NOTES_ID_BEGIN,
  payload,
});

export const createNotesAction = (payload: { items: any }) => ({
  type: CREATE_NOTES_BEGIN,
  payload,
});

export const deleteNoteAction = (payload: DeleteNoteType) => ({
  type: DELETE_NOTES_BEGIN,
  payload,
});

export const getCommentsAction = (payload: getCommentsType) => ({
  type: GET_COMMENTS_BEGIN,
  payload,
});

export const createCommentsAction = (payload: CreateCommentsType) => ({
  type: CREATE_COMMENTS_BEGIN,
  payload,
});

export const updateCommentsAction = (payload: UpdateCommentsType) => ({
  type: UPDATE_COMMENTS_BEGIN,
  payload,
});

export const deleteCommentsAction = (payload: DeleteCommentsType) => ({
  type: DELETE_COMMENTS_BEGIN,
  payload,
});
