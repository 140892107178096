import { HideToastAction, ShowToastAction } from "../../helpers/types";
import { HIDE_TOAST, SHOW_TOAST } from "../constant";

export const showToast = (message:string, severity:string):ShowToastAction  => ({
  type: SHOW_TOAST,
  payload: { message, severity },
});

export const hideToast = ():HideToastAction => ({
  type: HIDE_TOAST,
});