import { Box, styled, Paper } from '@mui/material';

export const ViewModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '90%%',
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 3,
};

export const NotesHeader = styled(Box)({
  display: 'flex',
  marginBottom: '12px',
  justifyContent: 'space-between',
});

export const NotesFooter = styled(Box)({
  display: 'flex',
  marginBottom: '3px',
  margintop: '10px',
  justifyContent: 'space-between',
});

export const ViewNotesWrapper = styled(Box)({
  padding: '10px 0',
  flexGrow: 1,
  borderTop: '1px solid lightgray',
});

export const NotesWrapper = styled(Box)({
  height: '60vh',
  overflow: 'auto',
  padding: '6px',
});

export const CommentsWrapper = styled(Paper)({
  padding: '8px',
  height: '300px',
  overflow: 'auto',
  marginBottom: '10px',
  backgroundColor: '#f1faff',
});

export const NotesLoader = styled(Box)({
  width: '60%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  height: '50vh',
  position: 'absolute',
});

export const CreateCommentsBtn = {
  marginTop: '10px',
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26)',
    background: 'rgba(0, 0, 0, 0.12)',
  },
};
