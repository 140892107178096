import React from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, GradientButton, Input } from '../../components';
import { ButtonWrapper } from '../CompaniesList/Style/CompaniesList.style';
import {
  AddDomainDialog,
  CloseIconStyle,
  DomainLabel,
} from '../CompanyDetails/Tabs/Style/Tabs.style';

const AddActiveLoadDomainModal = ({ handleClose, open }: any) => {
  return (
    <div>
      <AddDomainDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Load Active Defense
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ '&.MuiDialogContent-root': { p: '16px 32px' } }}>
          <DomainLabel>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Domains
            </Typography>
            <div style={{ width: '100%' }}>
              <Input
                size="small"
                // value={protectedDomainName}
                // onChange={handleChangeProtectedDomain}
                multiline
                rows={3}
                placeholder="domain1, domain2"
                sx={{ width: '100%' }}
              />
            </div>
          </DomainLabel>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton variant="contained" onClick={handleClose}>
            Submit Act Defense
          </GradientButton>
        </ButtonWrapper>
      </AddDomainDialog>
    </div>
  );
};

export default AddActiveLoadDomainModal;
