import {
  GET_HISTORY_STATUSES_BEGIN,
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_LABLE_BEGIN,
  GET_LABLE_FAILURE,
  GET_LABLE_SUCCESS,
  GET_NETWORK_BEGIN,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE,
  GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS,
  GET_NETWORK_FAILURE,
  GET_NETWORK_SUCCESS,
  GET_PLATFORM_BEGIN,
  GET_PLATFORM_FAILURE,
  GET_PLATFORM_SUCCESS,
  GET_REGION_CODE_BEGIN,
  GET_REGION_CODE_FAILURE,
  GET_REGION_CODE_SUCCESS,
  GET_SUBDOMAINS_BEGIN,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
} from './../constant';

const initialState = {
  subDomains: {
    loading: false,
    data: [],
    error: null,
  },
  getNetwork: {
    loading: false,
    data: [],
    error: null,
  },
  historyStatuses: {
    loading: false,
    data: [],
    error: null,
  },
  networkThreats: {
    loading: false,
    data: [],
    error: null,
  },
  regionCodes: {
    loading: false,
    data: [],
    error: null,
  },
  platforms: {
    loading: false,
    data: [],
    error: null,
  },
  labels: {
    loading: false,
    data: [],
    error: null,
  },
};

export const singleThreatReducer = (
  state = initialState,
  action: { type: string; payload: string },
) => {
  switch (action.type) {
    case GET_SUBDOMAINS_BEGIN:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: true,
        },
      };
    case GET_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SUBDOMAINS_FAILURE:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          error: action.payload,
        },
      };
    case GET_NETWORK_BEGIN:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: true,
        },
      };
    case GET_NETWORK_SUCCESS:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: false,
          data: action.payload,
        },
      };
    case GET_NETWORK_FAILURE:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: false,
          error: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_BEGIN:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: true,
        },
      };
    case GET_HISTORY_STATUSES_SUCCESS:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          data: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_FAILURE:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          error: action.payload,
        },
      };

    case GET_REGION_CODE_BEGIN:
      return {
        ...state,
        regionCodes: {
          ...state.regionCodes,
          loading: true,
        },
      };
    case GET_REGION_CODE_SUCCESS:
      return {
        ...state,
        regionCodes: {
          ...state.regionCodes,
          loading: false,
          data: action.payload,
        },
      };
    case GET_REGION_CODE_FAILURE:
      return {
        ...state,
        regionCodes: {
          ...state.regionCodes,
          loading: false,
          error: action.payload,
        },
      };

    case GET_PLATFORM_BEGIN:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          loading: true,
        },
      };
    case GET_PLATFORM_SUCCESS:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          loading: false,
          data: action.payload,
        },
      };
    case GET_PLATFORM_FAILURE:
      return {
        ...state,
        platforms: {
          ...state.platforms,
          loading: false,
          error: action.payload,
        },
      };

    case GET_NETWORK_DISRUPTION_CHECK_THREAT_BEGIN:
      return {
        ...state,
        networkThreats: {
          ...state.networkThreats,
          loading: true,
        },
      };
    case GET_NETWORK_DISRUPTION_CHECK_THREAT_SUCCESS:
      return {
        ...state,
        networkThreats: {
          ...state.networkThreats,
          loading: false,
          data: action.payload,
        },
      };
    case GET_NETWORK_DISRUPTION_CHECK_THREAT_FAILURE:
      return {
        ...state,
        networkThreats: {
          ...state.networkThreats,
          loading: false,
          error: action.payload,
        },
      };

    case GET_LABLE_BEGIN:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: true,
        },
      };
    case GET_LABLE_SUCCESS:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: false,
          data: action.payload,
        },
      };
    case GET_LABLE_FAILURE:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
