import { addUserSaveType } from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getUserDetailsApi = async (payload: { id: string }) => {
  const config = {
    method: 'GET',
    url: `user/get?id=${payload.id}`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const getUserRoleApi = async (payload: string) => {
  const config = {
    method: 'GET',
    url: `role/all`,
    payload,
  };
  const res = await apiClient(config);
  return res;
};

export const addUserSaveApi = async (data: addUserSaveType) => {
  const config = {
    method: 'POST',
    url: `user/save`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addUserAuthorizeApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `user/authorize`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addUserJoinApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `user/join`,
    data,
  };
  const res = await apiClient(config);
  return res;
};

export const addUserSetPasswordApi = async (data: any) => {
  const config = {
    method: 'POST',
    url: `user/setPassword`,
    data,
  };
  const res = await apiClient(config);
  return res;
};
