import { HistoryStatuesType, SubmitData } from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getSubDomainsApi = async (DomainId: number) => {
  const config = {
    method: 'GET',
    url: `domain/subdomains?d=${DomainId}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getNetworkApi = async (asset_id: number) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${asset_id}/network`,
  };
  const res = await apiClient(config);
  return res;
};

export const getHistoryStatuesApi = async (data: HistoryStatuesType) => {
  const config = {
    method: 'GET',
    url: `threat/statuses?d=${data.d}&t=${data.t}`,
  };
  const res = await apiClient(config);
  return res;
};

export const fetchRegionCodesApi = async () => {
  const config = {
    method: 'GET',
    url: 'v2/assets/network_disruption/gsb/region_codes',
  };
  return await apiClient(config);
};

export const fetchNetworkCheckThreatApi = async () => {
  const config = {
    method: 'GET',
    url: 'v2/assets/network_disruption/gsb/abuse_types',
  };
  return await apiClient(config);
};

export const fetchPlatformApi = async () => {
  const config = {
    method: 'GET',
    url: 'v2/assets/network_disruption/gsb/platforms',
  };
  return await apiClient(config);
};

export const fetchLabelApi = async () => {
  const config = {
    method: 'GET',
    url: 'v2/assets/network_disruption/gsb/labels',
  };
  return await apiClient(config);
};

export const submitToGsbApi = async (data: SubmitData) => {
  const config = {
    method: 'POST',
    url: 'v2/assets/network_disruption/network_disruption/submit_to_gsb',
    data,
  };
  return await apiClient(config);
};
