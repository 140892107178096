import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  LatestThreatWrapper,
  OverviewTitle,
  PaperStyle,
  StatusLabel,
  TimelineCreated,
  ViewAllButton,
} from './Style/Tabs.style';
import { formatDateActivity } from '../../../utils';

const OverviewTab = ({ onViewAll }: any) => {
  const { getProtectedDomains, getCompanyDetail } = useSelector(
    (state: any) => state.company,
  );
  const latestThreat = getProtectedDomains?.data;
  const lastFourThreats =
    latestThreat?.Domains?.[0]?.Threats?.slice(-3)?.reverse();

  const users = Array.isArray(getCompanyDetail?.data?.Users)
    ? getCompanyDetail.data.Users.map((user: any) => ({
        ...user,
        type: 'User',
      }))
    : [];

  const domains = Array.isArray(getCompanyDetail?.data?.Domains)
    ? getCompanyDetail.data.Domains.map((domain: any) => ({
        ...domain,
        type: 'Domain',
      }))
    : [];

  const combinedActivity = [...users, ...domains];
  const lastThreeActivityLogs = combinedActivity.slice(-3)?.reverse();

  return (
    <Box sx={{ display: 'flex', gap: '28px' }}>
      <Paper elevation={1} sx={PaperStyle}>
        <OverviewTitle>
          <div>
            <Typography variant="body1" fontWeight={600}>
              Activity Logs
            </Typography>
            <Typography variant="body2">
              {combinedActivity?.length} Logs
            </Typography>
          </div>
          <ViewAllButton variant="contained" onClick={onViewAll}>
            View All
          </ViewAllButton>
        </OverviewTitle>
        {lastThreeActivityLogs?.map((item, index) => (
          <Timeline
            position="alternate"
            sx={{ padding: '0', mb: 0 }}
            key={index}>
            <TimelineItem>
              <TimelineCreated>
                {formatDateActivity(item.Created)}
              </TimelineCreated>
              <TimelineSeparator>
                <TimelineDot variant="outlined" color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ fontSize: '13px', fontWeight: 600 }}>
                {item.type === 'User' ? (
                  <>
                    {getCompanyDetail.data?.Name} added {item?.Firstname}{' '}
                    {item?.Lastname} as new contact!
                  </>
                ) : (
                  <>
                    {getCompanyDetail.data?.Name} added {item?.Name} as
                    protected domain!
                  </>
                )}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        ))}
      </Paper>
      <Paper elevation={1} sx={PaperStyle}>
        <OverviewTitle>
          <div>
            <Typography variant="body1" fontWeight={600}>
              Latest Threats
            </Typography>
          </div>
          <Link to={`/dashboard`}>
            <ViewAllButton variant="contained">View All</ViewAllButton>
          </Link>
        </OverviewTitle>
        {lastFourThreats?.map((item: any, index: number) => (
          <LatestThreatWrapper key={index}>
            <Typography variant="body2">{item?.Name}</Typography>
            <StatusLabel>{item?.CurrentStatus?.Status}</StatusLabel>
          </LatestThreatWrapper>
        ))}
      </Paper>
    </Box>
  );
};

export default OverviewTab;
