import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { AddUserDialog, CloseIconStyle, UserLabel } from './Style/Tabs.style';
import {
  Button,
  Dropdown,
  GradientButton,
  Input,
  SelectOption,
} from '../../../components';
import { ButtonWrapper } from '../../CompaniesList/Style/CompaniesList.style';
import { AddUserModalType, CompaniesType } from '../../../helpers/types';
import { addUserSaveAction } from '../../../store/user/action';
import { validateEmail, validatePassword } from '../../../utils';

const labelText = { color: 'black', mb: 0.5 };

const AddUserModal = ({ handleClose, open }: AddUserModalType) => {
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedUserRole, setSelectedUserRole] = useState<any>(3);
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);
  const { getUserRole } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (getCompany?.data) {
      const company = getCompany.data.find(
        (company: any) => String(company.Id) === id,
      );
      if (company) {
        setSelectedCompany({ label: company.Name, id: company.Id });
      }
    }
  }, [getCompany, id]);

  const handleCompanyChange = (event: any, value: any) => {
    setSelectedCompany(value);
  };

  const handleUserRoleChange = (e: any) => {
    setSelectedUserRole(e.target.value);
  };

  const handleFirstnameChange = (e: any) => {
    setFirstname(e.target.value);
    if (e.target.value.trim()) {
      setValidationErrors(prev => ({ ...prev, firstname: '' }));
    }
  };

  const handleLastnameChange = (e: any) => {
    setLastname(e.target.value);
    if (e.target.value.trim()) {
      setValidationErrors(prev => ({ ...prev, lastname: '' }));
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    const emailError = validateEmail(e.target.value);
    setValidationErrors(prev => ({ ...prev, email: emailError }));
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const passwordError = validatePassword(newPassword);
    setValidationErrors(prev => ({ ...prev, password: passwordError }));
  };

  const validateFields = () => {
    const errors = {
      firstname: firstname.trim() ? '' : 'Firstname is required',
      lastname: lastname.trim() ? '' : 'Lastname is required',
      email: validateEmail(email),
      password: validatePassword(password),
    };
    setValidationErrors(errors);
    return (
      !errors.firstname && !errors.lastname && !errors.email && !errors.password
    );
  };

  const handleAddUser = () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }
    dispatch(
      addUserSaveAction({
        firstname,
        lastname,
        email,
        password,
        username: email,
        roleid: selectedUserRole,
        companyid: id,
      }),
    );
    setFirstname('');
    setLastname('');
    setEmail('');
    setPassword('');
    handleClose();
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );

  return (
    <>
      <AddUserDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
          Add a Company User
        </DialogTitle>
        <IconButton onClick={handleClose} sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            <Typography sx={labelText}>
              Firstname <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Input
              size="small"
              placeholder="Sean"
              sx={{ width: '100%' }}
              value={firstname}
              onChange={handleFirstnameChange}
              error={!!validationErrors.firstname}
              helperText={validationErrors.firstname}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography sx={labelText}>
              Lastname <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Input
              size="small"
              placeholder="Bean"
              sx={{ width: '100%' }}
              value={lastname}
              onChange={handleLastnameChange}
              error={!!validationErrors.lastname}
              helperText={validationErrors.lastname}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography sx={labelText}>
              Email <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Input
              size="small"
              placeholder="sean@gmail.com"
              sx={{ width: '100%' }}
              value={email}
              onChange={handleEmailChange}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography sx={labelText}>
              Password <span style={{ color: 'red' }}>*</span>
            </Typography>
            <span>
              <Input
                size="small"
                type="password"
                placeholder="*****"
                sx={{ width: '100%' }}
                value={password}
                onChange={handlePasswordChange}
                error={!!validationErrors.password}
                helperText={validationErrors.password}
              />
            </span>
          </Box>
          <UserLabel>
            <Typography sx={{ color: 'black', width: '40%' }}>
              Company <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Dropdown
              items={companiesData}
              size="small"
              placeholder="company list"
              label="company list"
              onChange={handleCompanyChange}
              selectedValues={selectedCompany}
              multiple={false}
            />
          </UserLabel>
          <UserLabel>
            <Typography sx={{ color: 'black', width: '40%' }}>
              Authorization <span style={{ color: 'red' }}>*</span>
            </Typography>
            <SelectOption
              label="company-user"
              size="small"
              onChange={handleUserRoleChange}
              value={selectedUserRole}
              options={getUserRole.data?.map((item: any) => ({
                value: item.Id,
                label: item.Name,
              }))}
              width="100%"
            />
          </UserLabel>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <GradientButton
            variant="contained"
            type="submit"
            disabled={
              !!validationErrors.firstname ||
              !!validationErrors.lastname ||
              !!validationErrors.email ||
              !!validationErrors.password
            }
            onClick={handleAddUser}>
            Submit
          </GradientButton>
        </ButtonWrapper>
      </AddUserDialog>
    </>
  );
};

export default AddUserModal;
