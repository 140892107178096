import { AnalyticActionType } from "../../helpers/types";
import { SET_ANALYTIC_DATA } from "../constant";

const initialState = {
  pageName: '',
  user: {
    email: '',
    name: ''
  },
  isBulkUpdate: false
};
export const analyticReducer = (
  state = initialState,
  action: AnalyticActionType,
) => {
  switch (action.type) {
  case SET_ANALYTIC_DATA:
    return action.payload;
  default:
    return state;
  }
}