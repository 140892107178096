import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getKeywordsAction } from '../../../store/company/action';
import { NoDataMessage, LoaderWrapper } from '../../../utils';

const KeywordsTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getKeywords } = useSelector((state: any) => state.company);

  useEffect(() => {
    if (id) {
      dispatch(getKeywordsAction({ c: id }));
    }
  }, [dispatch, id]);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ fontWeight: 600, p: '4px 24px', m: 0 }}>
          Entities keywords
        </AccordionSummary>
        <AccordionDetails sx={{ p: '8px 24px 10px' }}>
          <Table size="small">
            {getKeywords.loading ? (
              <LoaderWrapper size={25} />
            ) : getKeywords.data?.length === 0 ? (
              <NoDataMessage message="No data found" />
            ) : (
              getKeywords.data?.map((item: any) => (
                <Typography
                  key={item.Id}
                  variant="body2"
                  sx={{ fontWeight: 600, mb: 1.5 }}>
                  {item?.Text}
                </Typography>
              ))
            )}
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default KeywordsTab;
