import { call, put } from 'redux-saga/effects';
import {
  ADD_CHILD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
  ADD_PROTECTED_DOMAINS_FAILURE,
  ADD_PROTECTED_DOMAINS_SUCCESS,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_SUCCESS,
  GET_COMPANYDETAIL_FAILURE,
  GET_COMPANYDETAIL_SUCCESS,
  GET_COMPANYLIST_FAILURE,
  GET_COMPANYLIST_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_PROTECTED_DOMAINS_FAILURE,
  GET_PROTECTED_DOMAINS_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_KEYWORDS_FAILURE,
  GET_KEYWORDS_SUCCESS,
  GET_SERVICE_FAILURE,
  GET_SERVICE_SUCCESS,
  UPDATE_COMPANY_DETAILS_FAILURE,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_INDUSTRY_FAILURE,
  UPDATE_COMPANY_INDUSTRY_SUCCESS,
  UPDATE_COMPANY_SERVICE_FAILURE,
  UPDATE_COMPANY_SERVICE_SUCCESS,
  DOMAIN_UNPROTECT_SUCCESS,
  DOMAIN_UNPROTECT_FAILURE,
  CHANGE_STATUS_DOMAIN_SUCCESS,
  CHANGE_STATUS_DOMAIN_FAILURE,
  GET_DOMAIN_KEYWORDS_SUCCESS,
  GET_DOMAIN_KEYWORDS_FAILURE,
  ADD_DOMAIN_KEYWORDS_FAILURE,
  ADD_DOMAIN_KEYWORDS_SUCCESS,
  UNSET_PARENT_COMPANY_SUCCESS,
  UNSET_PARENT_COMPANY_FAILURE,
  ADDINFORMATION_GENERIC_SUCCESS,
  ADDINFORMATION_GENERIC_FAILURE,
} from '../constant';
import {
  addChildCompanyApi,
  addCompanyApi,
  addDomainIdProtectApi,
  addProtectedDomainsApi,
  deleteCompanyApi,
  getCompanyApi,
  getCompanyDetailApi,
  getCompanyListApi,
  getProtectedDomainsApi,
  getKeywordsApi,
  getServiceApi,
  updateCompanyDetailsApi,
  updateCompanyIndustryApi,
  updateCompanyServiceApi,
  domainUnprotectApi,
  changeStatusDomainApi,
  getDomainKeywordsApi,
  addDomainKeywordsApi,
  unsetParentCompanyApi,
  addInformationGenericApi,
} from '.';

export function* getCompanySaga(action: any): any {
  try {
    const response = yield call(getCompanyApi, action.payload);
    yield put({
      type: GET_COMPANY_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANY_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getCompanyListSaga(action: any): any {
  try {
    const response = yield call(getCompanyListApi, action.payload);
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANYLIST_FAILURE,
      payload: error.response.data,
    });
  }
}

export function* deleteCompanySaga(action: any): any {
  try {
    const response = yield call(deleteCompanyApi, action.payload.id);
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'y',
      u: 'n',
      s: 'y',
      h: action.payload.h,
      ipp: 10,
      pn: action.payload.pn,
      r: 'desc',
      m: 'created',
    });
    yield put({
      type: DELETE_COMPANY_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_COMPANY_FAILURE,
      payload: error.response.data,
    });
  }
}

export function* addCompanySaga(action: any): any {
  try {
    const response = yield call(addCompanyApi, action.payload);
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'y',
      u: 'n',
      s: 'y',
      h: '',
      ipp: 10,
      pn: 1,
      r: 'desc',
      m: 'created',
    });
    yield put({
      type: ADD_COMPANY_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_COMPANY_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addChildCompanySaga(action: any): any {
  try {
    const response = yield call(addChildCompanyApi, action.payload);
    yield put({
      type: ADD_CHILD_COMPANY_SUCCESS,
      payload: response.data,
    });
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'n',
      u: 'n',
      s: 'y',
      h: '',
      ipp: 10,
      pn: 1,
      c: action.payload.parentId,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_COMPANY_FAILURE,
      payload: error.response.data,
    });
  }
}

export function* unsetParentCompanySaga(action: any): any {
  try {
    const response = yield call(unsetParentCompanyApi, action.payload);
    yield put({
      type: UNSET_PARENT_COMPANY_SUCCESS,
      payload: response.data,
    });
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'n',
      u: 'n',
      s: 'y',
      h: '',
      ipp: 10,
      pn: 1,
      c: action.payload.parentId,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes.data,
    });
  } catch (error: any) {
    yield put({
      type: UNSET_PARENT_COMPANY_FAILURE,
      payload: error.response.data,
    });
  }
}

export function* getCompanyDetailSaga(action: any): any {
  try {
    const response = yield call(getCompanyDetailApi, action.payload);
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANYDETAIL_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getProtectedDomainsSaga(action: any): any {
  try {
    const response = yield call(getProtectedDomainsApi, action.payload);
    yield put({
      type: GET_PROTECTED_DOMAINS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_PROTECTED_DOMAINS_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addProtectedDomainsSaga(action: any): any {
  try {
    const response = yield call(addProtectedDomainsApi, action.payload.name);
    const domainIdProtectPayload: any = {
      domainId: response.data.Id,
      companyId: action.payload.companyId,
      ownDomain: action.payload.ownDomain,
    };
    if (action.payload.ownDomain) {
      domainIdProtectPayload.statusName = 'owned';
    }
    yield call(addDomainIdProtectApi, domainIdProtectPayload);
    yield put({
      type: ADD_PROTECTED_DOMAINS_SUCCESS,
      payload: response.data,
    });
    const getProtectedDomains = yield call(getProtectedDomainsApi, {
      c: action.payload.companyId,
      ipp: 10,
      pn: action.payload.pn,
      h: action.payload.h,
    });
    yield put({
      type: GET_PROTECTED_DOMAINS_SUCCESS,
      payload: getProtectedDomains.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_PROTECTED_DOMAINS_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* domainUnprotectSaga(action: any): any {
  try {
    const response = yield call(domainUnprotectApi, {
      domainId: action.payload.domainId,
      companyId: action.payload.companyId,
    });
    yield put({
      type: DOMAIN_UNPROTECT_SUCCESS,
      payload: response.data,
    });
    const getDomainsRes = yield call(getProtectedDomainsApi, {
      c: action.payload.companyId,
      ipp: 10,
      pn: action.payload.pn,
      h: action.payload.h,
    });
    yield put({
      type: GET_PROTECTED_DOMAINS_SUCCESS,
      payload: getDomainsRes.data,
    });
  } catch (error: any) {
    yield put({
      type: DOMAIN_UNPROTECT_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addInformationGenericSaga(action: any): any {
  try {
    const response = yield call(addInformationGenericApi, action.payload);
    yield put({
      type: ADDINFORMATION_GENERIC_SUCCESS,
      payload: response.data,
    });
    const getDomainsRes = yield call(getProtectedDomainsApi, {
      c: action.payload.c,
      ipp: 10,
      pn: 1,
      h: '',
    });
    yield put({
      type: GET_PROTECTED_DOMAINS_SUCCESS,
      payload: getDomainsRes.data,
    });
  } catch (error: any) {
    yield put({
      type: ADDINFORMATION_GENERIC_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* changeStatusDomainSaga(action: any): any {
  try {
    const response = yield call(changeStatusDomainApi, action.payload);
    yield put({
      type: CHANGE_STATUS_DOMAIN_SUCCESS,
      payload: response.data,
    });
    const getDomainsRes = yield call(getProtectedDomainsApi, {
      c: action.payload.companyId,
      ipp: 10,
      pn: 1,
      h: '',
    });
    yield put({
      type: GET_PROTECTED_DOMAINS_SUCCESS,
      payload: getDomainsRes.data,
    });
  } catch (error: any) {
    yield put({
      type: CHANGE_STATUS_DOMAIN_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getDomainKeywordsSaga(action: any): any {
  try {
    const response = yield call(getDomainKeywordsApi, action.payload);
    yield put({
      type: GET_DOMAIN_KEYWORDS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_DOMAIN_KEYWORDS_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* addDomainKeywordsSaga(action: any): any {
  try {
    const response = yield call(addDomainKeywordsApi, action.payload);
    yield put({
      type: ADD_DOMAIN_KEYWORDS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_DOMAIN_KEYWORDS_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getServiceSaga(action: any): any {
  try {
    const response = yield call(getServiceApi, action.payload);
    yield put({
      type: GET_SERVICE_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SERVICE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* updateCompanyDetailsSaga(action: any): any {
  try {
    const response = yield call(updateCompanyDetailsApi, action.payload);
    yield put({
      type: UPDATE_COMPANY_DETAILS_SUCCESS,
      payload: response.data,
    });
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action.payload.id,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_DETAILS_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* updateCompanyIndustrySaga(action: any): any {
  try {
    const response = yield call(updateCompanyIndustryApi, action.payload);
    yield put({
      type: UPDATE_COMPANY_INDUSTRY_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_INDUSTRY_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* updateCompanyServiceSaga(action: any): any {
  try {
    const response = yield call(updateCompanyServiceApi, action.payload);
    yield put({
      type: UPDATE_COMPANY_SERVICE_SUCCESS,
      payload: response.data,
    });
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action.payload.companyId,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_SERVICE_FAILURE,
      payload: error.response.data.message,
    });
  }
}

export function* getKeywordsSaga(action: any): any {
  try {
    const response = yield call(getKeywordsApi, action.payload);
    yield put({
      type: GET_KEYWORDS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_KEYWORDS_FAILURE,
      payload: error.response.data.message,
    });
  }
}
