import * as React from 'react';
import { Grid } from '@mui/material';
import ThreatCardSkeleton from './ThreatCardSkeleton';

const ThreatsSkeleton = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2 }}
      columns={{ md: 12 }}
      sx={{ paddingY: '0px' }}>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
      <Grid item md={3}>
        <ThreatCardSkeleton />
      </Grid>
    </Grid>
  );
};
export default ThreatsSkeleton;
