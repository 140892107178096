import { styled } from '@mui/material/styles';
import { default as SimpleButton } from '@mui/material/Button';
import { ButtonProps } from '../../helpers/types';

const CustomButton = styled(SimpleButton)<ButtonProps>(() => ({
  textTransform: 'capitalize',
  borderRadius: '3px',
  width: 'fit-content',
  height: 'fit-content',
  '&.MuiButton-root': {
    padding: '5px 13px',
  },
}));

const Button = ({
  variant,
  loading,
  label,
  disabled,
  onClick,
  style,
  size,
  type,
  children,
  startIcon,
  endIcon,
  sx,
  color,
  ...props
}: ButtonProps) => {
  return (
    <CustomButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      endIcon={endIcon}
      startIcon={startIcon}
      style={style}
      sx={sx}
      type={type}
      {...props}>
      {loading ? 'Loading...' : label}
      {children}
    </CustomButton>
  );
};

export default Button;
