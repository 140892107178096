import React from 'react';
import { Box } from '@mui/material';
import { StatusLabelProps } from '../../helpers/types';

const statusLabel = {
  padding: '4px 10px',
  color: 'white',
  borderRadius: '15px',
  fontSize: '12px',
};

const StatusLabel = ({ label, bgcolor, style }: StatusLabelProps) => {
  return <Box sx={{ backgroundColor: bgcolor, ...statusLabel }} style={style}>{label}</Box>;
};

export default StatusLabel;
