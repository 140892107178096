import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, IconButton, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import EditCommentsIcon from '../../assets/Images/EditCommentsIcon.svg';
import {
  ButtonsStyle,
  CommentsText,
  CommentsWrapper,
  CreatedByLabel,
  EditCommentsIconStyle,
  EditModeWrapper,
  LinesStyle,
  NoteLabelWrapper,
  PrivateLabel,
  SendCommentsBtn,
  UpdateButton,
} from './Style/SingleThreat.style';
import { Button, GradientButton, Input } from '../../components';
import {
  createCommentsAction,
  createNotesAction,
  getNotesIdAction,
  getCommentsAction,
  updateCommentsAction,
} from '../../store/noteComments/action';
import { ThreatTypes } from '../../helpers/types';
import NoteList from '../../components/NoteList/NoteList';

const NotesComments = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const [addThreatComments, setAddThreatComments] = useState<string>('');
  const [editMode, setEditMode] = useState<number | null>(null);
  const [editedComment, setEditedComment] = useState<string>('');
  const [editedIsPublic, setEditedIsPublic] = useState<boolean>(true);
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);

  const dispatch = useDispatch();
  const { getComments, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );
  const getCommentsList = getComments?.data?.items;

  useEffect(() => {
    if (singleThreat?.threat_id) {
      dispatch(
        getNotesIdAction({
          domain_id: singleThreat?.protected_asset_id,
          threat_id: singleThreat?.threat_id,
          page: 1,
          size: 50,
        }),
      );
      dispatch(
        getCommentsAction({
          domain_id: singleThreat?.protected_asset_id,
          threat_id: singleThreat?.threat_id,
        }),
      );
    }
  }, [singleThreat?.threat_id]);

  useEffect(() => {
    if (getNoteId?.data?.items) {
      const noteIds = getNoteId.data.items.map(
        (item: { note_id: number }) => item.note_id,
      );
      setSelectedNoteIds(noteIds);
    }
  }, [getNoteId]);

  const handleChangeComments = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddThreatComments(e.target.value);
  };

  const handleAddComments = () => {
    if (!addThreatComments.trim()) {
      return;
    }
    dispatch(
      createCommentsAction({
        items: [
          {
            comments: addThreatComments,
            domain_id: singleThreat?.protected_asset_id,
            threat_id: singleThreat?.threat_id,
            is_public: editedIsPublic,
          },
        ],
      }),
    );
    setAddThreatComments('');
  };

  const handleToggleEditMode = (index: number) => {
    if (editMode === index) {
      setEditMode(null);
    } else {
      setEditMode(index);
      setEditedComment(getCommentsList[index].comments);
      setEditedIsPublic(getCommentsList[index].is_public);
    }
  };

  const handleChangeEditedComment = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditedComment(e.target.value);
  };

  const handleUpdateComment = (id: number) => {
    dispatch(
      updateCommentsAction({
        comments: editedComment,
        comment_id: id,
        is_public: editedIsPublic,
        domain_id: singleThreat?.protected_asset_id,
        threat_id: singleThreat?.threat_id,
      }),
    );
    setEditMode(null);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleToggleIsPublic = () => {
    setEditedIsPublic(!editedIsPublic);
  };

  const handleApplyNotes = () => {
    const items = selectedNoteIds.map(noteId => ({
      domain_id: singleThreat?.protected_asset_id,
      threat_id: singleThreat?.threat_id,
      note_id: noteId,
    }));
    dispatch(
      createNotesAction({
        items,
      }),
    );
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <NoteLabelWrapper>
        <Typography variant="h6" sx={{ color: 'black' }}>
          Notes & Comments
        </Typography>
      </NoteLabelWrapper>
      <Grid container spacing={{ xs: 1 }} columns={{ xs: 12 }}>
        <NoteList
          handleNotesChange={handleNotesChange}
          selectedNoteIds={selectedNoteIds}
          handleApplyNotes={handleApplyNotes}
        />
        {getCommentsList?.length === 0 ? (
          <Typography variant="subtitle1" sx={{ color: 'black', m: 2 }}>
            No Comments Found
          </Typography>
        ) : (
          getCommentsList?.map((item: any, index: number) => {
            const createdDate = new Date(item?.created_at).toLocaleString();
            return (
              <CommentsWrapper key={index}>
                <EditModeWrapper>
                  {editMode === index ? (
                    <>
                      <Input
                        size="small"
                        value={editedComment}
                        onChange={handleChangeEditedComment}
                        sx={{ width: '100%' }}
                      />
                      <Switch
                        {...label}
                        defaultChecked
                        onChange={handleToggleIsPublic}
                      />
                      <UpdateButton
                        variant="contained"
                        onClick={() => handleUpdateComment(item.id)}>
                        Update
                      </UpdateButton>
                    </>
                  ) : (
                    <CommentsText>
                      {item.comments}
                      <IconButton
                        onClick={() => handleToggleEditMode(index)}
                        sx={{ p: 0, ml: 1 }}>
                        <img
                          src={EditCommentsIcon}
                          alt="editIcon"
                          style={EditCommentsIconStyle}
                        />
                      </IconButton>
                      {item.is_public && <PrivateLabel>Private</PrivateLabel>}
                    </CommentsText>
                  )}
                </EditModeWrapper>
                <CreatedByLabel>
                  <Typography sx={{ fontSize: 14 }}>
                    by {item?.created_by} on
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 14 }}
                    color="text.secondary">
                    {createdDate}
                  </Typography>
                </CreatedByLabel>
                <hr style={LinesStyle} />
              </CommentsWrapper>
            );
          })
        )}
        <Box sx={{ mt: 1.5, display: 'flex', width: '100%' }}>
          <Input
            type="text"
            size="small"
            label="Add a comment"
            value={addThreatComments}
            onChange={handleChangeComments}
            sx={{ width: '100%' }}
          />
        </Box>
        <ButtonsStyle>
          <GradientButton
            variant="contained"
            sx={SendCommentsBtn}
            onClick={handleAddComments}
            disabled={!addThreatComments.trim()}>
            Add comment
          </GradientButton>
          <Button variant="contained" sx={{ background: '#291b67' }}>
            Cancel
          </Button>
        </ButtonsStyle>
      </Grid>
    </>
  );
};

export default NotesComments;
