import { Box, Tab, Tabs, Typography, styled } from '@mui/material';
import { StyledTabProps, StyledTabsProps } from '../../../helpers/types';

export const CompanyNameWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const ThreatNumberWrapper = styled(Box)(() => ({
  display: 'flex',
  marginTop: '20px',
  gap: '14px',
}));

export const ThreatNumberStyle = styled(Box)(() => ({
  border: '1px solid gray',
  padding: '10px',
  minWidth: '125px',
  borderStyle: 'dashed',
  borderRadius: '.475rem',
}));

export const PaperStyle = {
  backgroundColor: 'white',
  mt: 2,
  mb: 3,
  p: 2.5,
};

export const LogoStyle = {
  height: 150,
  width: 150,
  border: '1px solid black',
  borderRadius: '10px',
};

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))();

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(15),
  color: 'black',
  '&.Mui-selected': {
    color: '#00b2ff',
  },
}));

export const ServiceLabel = styled(Typography)(() => ({
  backgroundColor: '#e8fff3',
  color: '#50cd89',
  padding: '3px 9px',
  fontWeight: 600,
  fontSize: 12,
  borderRadius: '.475rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#50cd89',
    color: 'white',
  },
}));

export const OverviewLabel = styled(Typography)(() => ({
  gap: '4px',
  display: 'flex',
  marginRight: '16px',
  alignItems: 'center',
  textWrap: 'nowrap',
}));
