import { addUserSaveType } from '../../helpers/types';
import {
  ADD_USER_SAVE_BEGIN,
  GET_USER_DETAILS_BEGIN,
  GET_USER_ROLE_BEGIN,
} from '../constant';

export const getUserDetailsAction = (payload: { id: string }) => ({
  type: GET_USER_DETAILS_BEGIN,
  payload,
});

export const getUserRoleAction = (payload: string) => ({
  type: GET_USER_ROLE_BEGIN,
  payload,
});

export const addUserSaveAction = (payload: addUserSaveType) => ({
  type: ADD_USER_SAVE_BEGIN,
  payload,
});
