import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { ToasterProps } from '../../helpers/types';

const style = {
  position: 'absolute',
  padding: '14px 8px',
  width: '-webkit-fill-available',
  top: 0,
  marginLeft: '-14px',
  borderRadius: 2,
  color: 'white',
  zIndex: 999,
  display: 'flex',
  justifyContent: 'space-between',
};

const Toaster = ({ title, backgroundColor, onCloseToaster }: ToasterProps) => {
  return (
    <Box sx={{ backgroundColor, ...style }}>
      <div> {title}</div>
      <div>
        <CloseIcon onClick={onCloseToaster} />
      </div>
    </Box>
  );
};

export default Toaster;
