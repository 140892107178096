import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  CheckboxStyle,
  FormControlLabelStyle,
} from '../../pages/ThreadsDetails/Style/SingleThreat.style';

const NoteList = ({ handleNotesChange, selectedNoteIds }: any) => {
  const { getNotesValues } = useSelector((state: any) => state.noteComments);

  return (
    <>
      {getNotesValues?.data?.map((item: any, index: number) => (
        <Grid item xs={6} key={index}>
          <Typography variant="body1" sx={{ my: 1, color: 'black' }}>
            {item.name}
          </Typography>
          {item.note_values?.map((note: any, noteIndex: number) =>
            note.deleted_at ? null : (
              <Tooltip title={note.description} arrow key={noteIndex}>
                <FormControlLabel
                  sx={FormControlLabelStyle}
                  control={
                    <Checkbox
                      sx={CheckboxStyle}
                      size="small"
                      checked={selectedNoteIds.includes(note.id)}
                      onChange={() => handleNotesChange(note.id)}
                    />
                  }
                  label={note.name}
                />
              </Tooltip>
            ),
          )}
        </Grid>
      ))}
    </>
  );
};

export default NoteList;
