import React, { useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { TableHeaderCell, TakedownTable } from './Style/SingleThreat.style';
import { formatedDate, TakedownListHeader } from '../../utils';
import { Loader } from '../../components';
import { ThreatTypes } from '../../helpers/types';
import {
  getStatusTakedown,
  getTakedownDetail,
} from '../../store/takedown/action';

const TakedownDetails = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const dispatch = useDispatch();

  const { takeDownDetails, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const takeDownItems = takeDownDetails?.data?.items || [];

  useEffect(() => {
    if (singleThreat?.identification_status?.value === 'confirmed') {
      dispatch(
        getTakedownDetail({
          until: '',
          since: '',
          asset_id: singleThreat.protected_asset_id,
          threat_id: singleThreat.threat_id,
          sort: 'created',
          order: 'descending',
          page: 1,
          size: 50,
        }),
      );
    }
  }, [singleThreat?.identification_status?.value]);

  useEffect(() => {
    dispatch(getStatusTakedown());
  }, []);

  const findStatusNameById = (id: number) => {
    const status = getStatustakeDown?.data?.find(
      (status: any) => status?.id === id,
    );
    return status ? status.name : 'Unknown';
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Takedown Details
      </Typography>
      <TakedownTable>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {TakedownListHeader.map((item, index) => (
                  <TableHeaderCell key={index} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            {takeDownDetails?.loading ? (
              <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
                <Box sx={{ m: 3 }}>
                  <Loader size={25} />
                </Box>
              </TableCell>
            ) : takeDownItems && takeDownItems.length === 0 ? (
              <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
                <Typography variant="subtitle1" sx={{ color: 'black', m: 3 }}>
                  No TakeDown Data
                </Typography>
              </TableCell>
            ) : (
              <TableBody>
                {takeDownItems?.map((item: any, index: number) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        height: '50px',
                      }}>
                      <TableCell>{item?.user_id}</TableCell>
                      <TableCell align="left">
                        {formatedDate(item?.created)}
                      </TableCell>
                      <TableCell align="left">
                        {formatedDate(item?.updated_at)}
                      </TableCell>
                      <TableCell align="left">
                        {findStatusNameById(item?.status_id)}
                      </TableCell>
                      <TableCell align="left">
                        {item.description || '-'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </TakedownTable>
    </>
  );
};

export default TakedownDetails;
