import React from 'react'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material';

const drawerWidth = 210;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none',
  borderBottom: '1px solid #e2e2e2',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: open ? `calc(100% - ${drawerWidth}px)` : '92%',
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('sm')]: { 
    width: open ? `calc(100% - ${drawerWidth}px)` : '90%', 
  },
  [theme.breakpoints.up('md')]: { 
    width: open ? `calc(100% - ${drawerWidth}px)` : '94%', 
  },
  [theme.breakpoints.up('lg')]: { 
    width: open ? `calc(100% - ${drawerWidth}px)` : '96%', 
  },
  [theme.breakpoints.up('xl')]: { 
    width: open ? `calc(100% - ${drawerWidth}px)` : '98%', 
  },
}));

export const HeaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));

export const HeaderWrapper = {
  display: 'flex',
  alignItems: 'center',
};
