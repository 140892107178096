import React, { useState } from 'react';
import {
  Typography,
  IconButton,
  Toolbar,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, HeaderContainer, HeaderWrapper } from './Style/Header.style';
import { HeaderProps } from '../../helpers/types';
import Wheel2Logo from '../../assets/Images/Wheel2Logo.png';

const Header = ({ open }: HeaderProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { logout, user } = useAuth0();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    localStorage.removeItem('userToken');
    setAnchorElUser(null);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <HeaderContainer>
          <Box sx={{ display: 'flex', gap: '6px' }}>
            <img src={Wheel2Logo} alt="wheel2" style={{ height: 30 }} />
          </Box>
          <Box sx={HeaderWrapper}>
            <Typography sx={{ ml: 6, fontSize: '14px' }}>
              {user?.name}
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                edge="end"
                onClick={handleOpenUserMenu}
                color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}>
                <MenuItem>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
