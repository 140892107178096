import { call, put, select } from 'redux-saga/effects';
import { showToast } from '../toaster/action';
import {
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_BULK_STATUS_SUCCESS,
  CHANGE_STATUSLABEL_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
  CREATE_THREAT_FAILURE,
  CREATE_THREAT_SUCCESS,
  GET_DOMAINS_LIST_FAILURE,
  GET_DOMAINS_LIST_SUCCESS,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_THREAT_DETAIL_FAILURE,
  GET_THREAT_DETAIL_SUCCESS,
  GET_THREAT_FAILURE,
  GET_THREAT_SUCCESS,
} from '../constant';
import {
  changeBulkStatusApi,
  changeStatusLabelApi,
  createThreatApi,
  getDomainsListApi,
  getStatusApi,
  getThreatApi,
  getThreatDetailApi,
} from '.';
import { EVENT_NAME, trackAnalytic } from '../../utils/analytic';

export function* getThreatSaga(action: any): any {
  try {
    const response = yield call(getThreatApi, action.payload);
    yield put({
      type: GET_THREAT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error.response.data.detail[0].msg ||
          error.response.data.detail ||
          'An unexpected error occurred. Please try again later',
        'error',
      ),
    );
    yield put({
      type: GET_THREAT_FAILURE,
      payload: error.response.data.detail[0].msg,
    });
  }
}

export function* getThreatDetailSaga(action: any): any {
  try {
    const response = yield call(getThreatDetailApi, action.payload);
    yield put({
      type: GET_THREAT_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error?.message ||
        error?.response?.data?.detail ||
          'An unexpected error occurred. Please try again later',
        'error',
      ),
    );
    yield put({
      type: GET_THREAT_DETAIL_FAILURE,
      payload: error.response.data.detail[0].msg,
    });
    yield put(
      showToast(
        error.response.data.detail[0].msg ||
          'An unexpected error occurred. Please try again later',
        'error',
      ),
    );
  }
}

export function* changeStatusLabel(action: any): any {
  try {
    const response = yield call(changeStatusLabelApi, action.payload);
    yield put(showToast('Status updated successfully', 'success'));
    yield put({
      type: CHANGE_STATUSLABEL_SUCCESS,
      payload: action.payload,
    });
    if (action.payload.singleThreatStatus) {
      const resGetThreatDetail = yield call(getThreatDetailApi, {
        protected_id: action?.payload?.domain_id,
        threat_id: action?.payload?.threat_id,
      });
      yield put({
        type: GET_THREAT_DETAIL_SUCCESS,
        payload: resGetThreatDetail.data,
      });
    }
    const state = yield select();
    const { protected_asset_id, protected_asset_name, threat_id, threat_name } =
      response.data;
    const analyticData = {
      domain: {
        id: protected_asset_id,
        name: protected_asset_name,
      },
      threat: {
        id: threat_id,
        name: threat_name,
      },
      currentStatus: action.payload.currentStatus,
      updatedStatus: action.payload.status,
      ...state.analytic,
    };
    yield call(trackAnalytic, EVENT_NAME.CHANGE_THREAT_STATUS, analyticData);
  } catch (error: any) {
    yield put(
      showToast(
        error.message ||
          error.response?.data?.detail?.[0].msg ||
          'Something want wrong',
        'error',
      ),
    );
    yield put({
      type: CHANGE_STATUSLABEL_FAILURE,
      error: error.response.data.detail || 'Something want wrong',
    });
  }
}

export function* changeBulkStatus(action: any): any {
  try {
    const response = yield call(changeBulkStatusApi, action.payload);
    yield put(showToast('Bulk status updated successfully', 'success'));
    yield put({
      type: CHANGE_BULK_STATUS_SUCCESS,
      payload: action.payload,
    });
    const state = yield select();
    const { protected_asset_id, protected_asset_name, threat_id, threat_name } =
      response.data;
    const analyticData = {
      domain: {
        id: protected_asset_id,
        name: protected_asset_name,
      },
      threat: {
        id: threat_id,
        name: threat_name,
      },
      currentStatus: action.payload.currentStatus,
      updatedStatus: action.payload.status,
      ...state.analytic,
    };
    yield call(trackAnalytic, EVENT_NAME.CHANGE_THREAT_STATUS, analyticData);
  } catch (error: any) {
    yield put({
      type: CHANGE_BULK_STATUS_FAILURE,
      error: error.response.data.detail || 'Something want wrong',
    });
    yield put(
      showToast(error.response.data.detail || 'Something want wrong', 'error'),
    );
  }
}

export function* createThreatSaga(action: any): any {
  try {
    const response = yield call(createThreatApi, action.payload);
    yield put(showToast('Threat created successfully', 'success'));
    yield put({
      type: CREATE_THREAT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(
      showToast(
        error.message ||
          error.response.data.detail[0].msg ||
          'Something want wrong',
        'error',
      ),
    );
    yield put({
      type: CREATE_THREAT_FAILURE,
      payload: error.response.data.detail,
    });
  }
}

export function* getDomainsListSaga(action: any): any {
  try {
    const response = yield call(getDomainsListApi, action.payload);
    yield put({
      type: GET_DOMAINS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_DOMAINS_LIST_FAILURE,
      payload: error.message,
    });
  }
}

export function* getStatusSaga(action: any): any {
  try {
    const response = yield call(getStatusApi, action.payload);
    yield put({
      type: GET_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put(showToast('Something want wrong', 'error'));
    yield put({
      type: GET_STATUS_FAILURE,
      payload: error.response.data.message,
    });
  }
}
