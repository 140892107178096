import { styled } from '@mui/material';
import { useErrorBoundary } from "react-error-boundary";

export const ErrorContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  marginTop: '40px',
});

export const MyFallbackComponent = ({ error, resetErrorBoundary }: any) => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <ErrorContainer role="alert">
      <h1>Something went wrong:</h1>
      <h3>{error.message}</h3>
      <button onClick={resetBoundary}>Try again</button>
    </ErrorContainer>
  );
};
