import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  DialogContent,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, GradientButton, Input } from '../../../components';
import { InformationButtonWrapper, InformationTitle } from './Style/Tabs.style';
import CompanyDetailsForm from '../CompanyDetailsForm';
import {
  updateCompanyIndustryAction,
  updateCompanyServiceAction,
} from '../../../store/company/action';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
};

const InformationTab = () => {
  const [formValues, setFormValues] = useState<any>({
    logo: '',
    companyName: '',
    vat: '',
    hubSpotId: '',
    address: '',
    postCode: '',
    contactPhone: '',
    email: '',
    parent: '',
    nbEmployees: 0,
    services: '',
    industryName: '',
  });
  const { getCompanyDetail, getService } = useSelector(
    (state: any) => state.company,
  );
  const companyDetail = getCompanyDetail.data;
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (companyDetail) {
      setFormValues({
        logo: companyDetail.Logo || '',
        companyName: companyDetail.Name || '',
        vat: companyDetail.Vat || '',
        hubSpotId: companyDetail.Properties?.hubSpotId || '',
        address: companyDetail.Address || '',
        postCode: companyDetail.Zip || '',
        contactPhone: companyDetail.Phone || '',
        email: companyDetail.Email || '',
        parent: companyDetail.Parent || '',
        nbEmployees: companyDetail.nbEmployees || 0,
        services: companyDetail?.Services[0] || '',
        industryName: companyDetail.Properties?.Industry || '',
      });
    }
  }, [companyDetail]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAutocompleteChange = (event: any, value: any, name: any) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChangeServices = () => {
    if (formValues.services && formValues.services.Id) {
      dispatch(
        updateCompanyServiceAction({
          companyId: id,
          serviceId: formValues.services.Id,
        }),
      );
    }
  };

  const handleCompanyIndustry = () => {
    dispatch(
      updateCompanyIndustryAction({
        company: id,
        key: 'Industry',
        value: formValues.industryName,
      }),
    );
  };

  return (
    <>
      <Paper elevation={1} sx={PaperStyle}>
        <InformationTitle>
          <span>Entity Details</span>
        </InformationTitle>
        <CompanyDetailsForm
          formValues={formValues}
          handleChangeInput={handleChangeInput}
          handleAutocompleteChange={handleAutocompleteChange}
        />
      </Paper>
      <Paper elevation={1} sx={{ ...PaperStyle, my: 3 }}>
        <InformationTitle>
          <span>Entity Services</span>
        </InformationTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ minWidth: '30%' }} variant="body2">
              Services
            </Typography>
            <Autocomplete
              options={getService?.data?.items ?? []}
              getOptionLabel={(option: any) => option.name || ''}
              value={
                getService.data?.items?.find(
                  (item: any) => item?.name === formValues.services?.Name,
                ) || null
              }
              onChange={(event, value) =>
                handleAutocompleteChange(event, value, 'services')
              }
              sx={{ width: '100%' }}
              renderInput={params => (
                <TextField {...params} label="Services" size="small" />
              )}
            />
          </Box>
        </DialogContent>
        <InformationButtonWrapper>
          <Button variant="outlined" color="secondary">
            Cancel
          </Button>
          <GradientButton variant="contained" onClick={handleChangeServices}>
            Save Changes
          </GradientButton>
        </InformationButtonWrapper>
      </Paper>
      <Paper elevation={1} sx={{ ...PaperStyle, my: 3 }}>
        <InformationTitle>
          <span>Entity Industry</span>
        </InformationTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography sx={{ minWidth: '30%' }} variant="body2">
              Industry Name
            </Typography>
            <Input
              size="small"
              name="industryName"
              value={formValues.industryName}
              onChange={handleChangeInput}
              placeholder="industry name"
              sx={{ width: '100%' }}
            />
          </Box>
        </DialogContent>
        <InformationButtonWrapper>
          <Button variant="outlined" color="secondary">
            Cancel
          </Button>
          <GradientButton variant="contained" onClick={handleCompanyIndustry}>
            Save Changes
          </GradientButton>
        </InformationButtonWrapper>
      </Paper>
    </>
  );
};

export default InformationTab;
