import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { showToast } from '../store/toaster/action';

const useApiInterceptor = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (error?.response && error?.response?.status === 401) {
        dispatch(
          showToast('Session expired. please log in again.', 'error'),
        );
        await new Promise(resolve => setTimeout(resolve, 5000));
        logout({ logoutParams: { returnTo: window.location.origin } });
      }
      return Promise.reject(error);
    },
  );
};

export default useApiInterceptor;
